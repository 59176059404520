<template>
  <div class="h-100 w-100">
    <div v-show="points[0]" ref="heatmap" class="h-100 w-100" />
    <div v-if="!points[0] && !loading">
      <quick-message
        message="No hay información para mostrar"
        show
        type="info"
        with-icon
        class="mb-2"
      />
    </div>
    <div v-show="loading">
      <loading-spinner></loading-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: "Heatmap",

  props: {
    points: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    center: {
      type: Object,
      required: false,
      default: () => ({
        lat: 20.6737777,
        lng: -103.4054536
      }),
      validator: p => {
        if (typeof p !== "object") return false;
        if (p === null) return false;
        return p.hasOwnProperty("lat") && p.hasOwnProperty("lng");
      }
    },
    centerLat: {
      type: Number,
      required: false,
      default: 20.6737777
    },
    centerLng: {
      type: Number,
      required: false,
      default: -103.4054536
    }
  },

  data() {
    return {
      mapLoaded: false,
      google: null,
      mapObject: null,
      heatmap: null
    };
  },

  computed: {
    heatmapPoints() {
      if (window.google) {
        return this.points.map(
          point => new window.google.maps.LatLng(point.lat, point.lng)
        );
      }
      return [];
    }
  },

  watch: {
    heatmapPoints: {
      handler() {
        this.renderMap();
      }
    },
    center: {
      handler() {
        if (this.mapObject != null) {
          this.mapObject.panTo(
            new window.google.maps.LatLng(this.center.lat, this.center.lng)
          );
        }
      },
      deep: true
    },
    centerLat: {
      handler() {
        if (this.mapObject != null) {
          this.mapObject.panTo(
            new window.google.maps.LatLng(this.centerLat, this.centerLng)
          );
        }
      }
    },
    centerLng: {
      handler() {
        if (this.mapObject != null) {
          this.mapObject.panTo(
            new window.google.maps.LatLng(this.centerLat, this.centerLng)
          );
        }
      }
    }
  },

  mounted() {
    // Load the first heatmap using currently available data.
    this.$gmapApiPromiseLazy({}).then(() => {
      const mapElement = this.$refs.heatmap;
      this.google = google;
      this.mapLoaded = true;
      this.mapObject = new window.google.maps.Map(mapElement, {
        zoom: 11,
        center: { lat: this.center.lat, lng: this.center.lng },
        mapTypeId: "roadmap"
      });
      this.heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: this.heatmapPoints,
        map: this.mapObject
      });
      this.heatmap.setMap(this.mapObject);
    });
  },

  methods: {
    renderMap() {
      if (this.mapLoaded) {
        // First remove the layer from the map.
        this.heatmap.setMap(null);
        // Create a new heatmap layer with the updated points.
        this.heatmap = new window.google.maps.visualization.HeatmapLayer({
          data: this.heatmapPoints,
          map: this.mapObject
        });
        // Reassign the heatmap layer to the map object.
        this.heatmap.setMap(this.mapObject);
      }
    }
  }
};
</script>
