<template>
  <div>
    <b-row>
      <b-col lg="4">
        <div class="rounded border p-4 h-100">
          <div class="h5 font-weight-bold mb-0">
            Modulo 1
          </div>
        </div>
      </b-col>
      <b-col class="mt-4 mt-lg-0" lg="8">
        <div class="rounded border p-4">
          <div class="d-sm-flex justify-content-between">
            <div class="h5 font-weight-bold mb-0">
              Modulo 2
            </div>
            <div class="d-sm-flex mt-3 mt-sm-0">
              <z-dropdown
                id="filter-1"
                v-model="selectedOption"
                class="mr-3 mt-3 mt-sm-0"
                toggle-class="h-100"
                name="Filtro 1"
                :options="options"
              />
              <z-dropdown
                id="filter-1"
                v-model="selectedOption"
                class="mr-3 mt-3 mt-sm-0"
                toggle-class="h-100"
                name="Filtro 2"
                :options="options"
              />
              <z-dropdown
                id="filter-1"
                v-model="selectedOption"
                class="mr-3 mt-3 mt-sm-0"
                toggle-class="h-100"
                name="Filtro 3"
                :options="options"
              />
              <z-button
                size="md"
                variant="secondary"
                fit="auto"
                class="text-dark mt-3 mt-sm-0"
              >
                <font-awesome-icon icon="download" />
              </z-button>
            </div>
          </div>
          <div class="placeholder-map mt-4"></div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col lg="6">
        <div class="rounded border p-4">
          <div class="d-sm-flex justify-content-between mb-5">
            <div class="h5 font-weight-bold mb-0">
              Modulo 3
            </div>
            <div class="d-flex mt-3 mt-sm-0">
              <z-dropdown
                id="filter-1"
                v-model="selectedOption"
                class="mr-3"
                toggle-class="h-100"
                name="Filtro 1"
                :options="options"
              />
              <z-button
                size="md"
                variant="secondary"
                fit="auto"
                class="text-dark"
              >
                <font-awesome-icon icon="download" />
              </z-button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="mt-4 mt-lg-0" lg="6">
        <div class="rounded border p-4">
          <div class="d-sm-flex justify-content-between mb-5">
            <div class="h5 font-weight-bold mb-0">
              Modulo 4
            </div>
            <div class="d-flex mt-3 mt-sm-0">
              <z-dropdown
                id="filter-1"
                v-model="selectedOption"
                class="mr-3"
                toggle-class="h-100"
                name="Filtro 1"
                :options="options"
              />
              <z-button
                size="md"
                variant="secondary"
                fit="auto"
                class="text-dark"
              >
                <font-awesome-icon icon="download" />
              </z-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";

export default {
  name: "FinancesStatistics",

  components: {
    ZDropdown
  },

  data() {
    return {
      selectedOption: null,
      options: [
        { value: null, text: "Todos" },
        { value: 1, text: "Opcion 1" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.placeholder-map {
  height: 375px;
  width: 100%;
  background-color: $gainsboro;
}
</style>
