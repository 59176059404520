<template>
  <div>
    <b-row>
      <b-col lg="4">
        <div class="rounded border p-4 h-100">
          <clients-statistics-resume />
        </div>
      </b-col>
      <b-col class="mt-4 mt-lg-0" lg="8">
        <div class="rounded border p-4">
          <services-chart />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ClientsStatisticsResume from "./ClientsStatisticsResume";
import ServicesChart from "./ServicesChart";

export default {
  name: "ClientsStatistics",

  components: {
    ClientsStatisticsResume,
    ServicesChart
  }
};
</script>

<style lang="scss" scoped>
.placeholder-map {
  height: 375px;
  width: 100%;
  background-color: $gainsboro;
}
</style>
