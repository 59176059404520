<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <div class="h5 font-weight-bold mb-3">
        No. Servicios p\Tipo
      </div>
    </div>
    <div class="d-md-flex mt-3 mt-md-0">
      <z-dropdown
        id="filter-peridiocity"
        v-model="selectedDate"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Fecha"
        :options="dateOptions"
        :disabled="loading"
      />
      <z-date-picker
        v-show="showCustomDateInputs"
        v-model="customDate.from"
        placeholder="Desde"
        class="mr-3"
        @changed="getStats"
      />
      <z-date-picker
        v-show="showCustomDateInputs"
        v-model="customDate.to"
        placeholder="Hasta"
        class="mr-3"
        @changed="getStats"
      />
      <z-dropdown
        id="filter-type"
        v-model="selectedCity"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Ciudad"
        :options="cityOptions"
        :disabled="loading"
      />
      <z-dropdown
        id="filter-1"
        v-model="selectedClient"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Cliente"
        :disabled="loading"
        :options="clientsOptions"
        search
      />
    </div>
    <div class="chart-container border rounded mt-4 bg-light position-relative">
      <div
        v-show="loading"
        class="loading-container rounded"
        :class="{ 'position-absolute fixed-top': selectedData.length }"
      >
        <loading-spinner />
      </div>
      <bar-chart v-if="selectedData.length" class="p-3" :data="parsedData">
      </bar-chart>
    </div>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import _random from "lodash/random";
import BarChart from "@/app/components/Charts/BarChart.vue";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import DateFilters from "@/constants/filters/date";
import ServiceType from "@zubut/common/src/constants/services/type";
import Reports from "@/services/reports";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";

export default {
  name: "ServicesChart",

  components: {
    BarChart,
    ZDatePicker,
    ZDropdown
  },

  data() {
    return {
      dateOptions: [
        { value: DateFilters.NUM_THIS_MONTH, text: DateFilters.THIS_MONTH },
        { value: DateFilters.NUM_LAST_MONTH, text: DateFilters.LAST_MONTH },
        { value: DateFilters.NUM_THIS_YEAR, text: DateFilters.THIS_YEAR },
        { value: DateFilters.NUM_CUSTOM, text: DateFilters.CUSTOM }
      ],
      selectedData: [],
      selectedCity: null,
      selectedDate: DateFilters.NUM_THIS_MONTH,
      selectedClient: null,
      loading: true,
      customDate: {
        from: null,
        to: null
      }
    };
  },

  computed: {
    cityOptions() {
      return this.$store.getters["cities/cityOptions"];
    },
    clientsOptions() {
      return this.$store.getters["clients/clientsOptions"];
    },
    parsedData() {
      return this.selectedData.map(d => {
        return {
          y: d.count,
          x: d.serviceType
        };
      });
    },
    showCustomDateInputs() {
      return this.selectedDate === DateFilters.NUM_CUSTOM;
    }
  },

  watch: {
    selectedCity() {
      this.getStats();
    },
    selectedDate() {
      if (this.selectedDate !== DateFilters.NUM_CUSTOM) {
        this.getStats();
      }
    },
    selectedClient() {
      this.getStats();
    }
  },

  created() {
    this.getStats();
  },

  methods: {
    getStats() {
      this.loading = true;
      let from = null;
      let to = null;

      if (this.selectedDate !== DateFilters.NUM_CUSTOM) {
        const date = dateRange(this.selectedDate);
        this.customDate.from = date.from;
        this.customDate.to = date.until;
        from = formatToISO(date.from);
        to = formatToISO(date.until.format);
      } else {
        from = this.customDate.from;
        to = this.customDate.to;
      }

      Reports.mostRequestedServices({
        clientId: this.selectedClient,
        cityId: this.selectedCity,
        from,
        to
      })
        .then(res => {
          const multiPoint = {
            serviceType: ServiceType.MULTI_POINT,
            count: 0
          };
          const parsedData = [multiPoint];
          res.forEach(el => {
            let serviceType = "";
            if (ServiceType.isMultiPoint(el.serviceType)) {
              multiPoint.count += el.count;
            } else {
              serviceType = ServiceType.get[el.serviceType];
              parsedData.push({
                serviceType,
                count: el.count
              });
            }
          });
          this.selectedData = parsedData;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  min-height: 150px;
}

.loading-container {
  display: flex;
  min-height: 383px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: $white;
  opacity: 0.4;
}

@include media-breakpoint-up(md) {
  .chart-container {
    min-height: 383px;
  }
}
</style>
