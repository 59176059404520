<template>
  <div>
    <div class="h5 font-weight-bold mb-0">
      Resumen de Estadísticas
    </div>
    <div class="h6 text-muted">Datos de {{ formattedDate }}</div>
    <hr />
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center py-5"
    >
      <loading-spinner></loading-spinner>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-between">
        <div class="stats-title">Clientes registrados</div>
        <div class="stats-number mr-3">{{ registeredClients }}</div>
      </div>
      <hr />
      <div class="d-flex align-items-center justify-content-between">
        <div class="stats-title">Clientes que han creado servicios</div>
        <div class="stats-number mr-3">{{ clientsWithServices }}</div>
      </div>
      <hr />
      <div class="d-flex align-items-center justify-content-between">
        <div class="stats-title">Clientes que no han creado servicios</div>
        <div class="stats-number mr-3">{{ clientsWithoutServices }}</div>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import Reports from "@/services/reports";
import { format } from "@zubut/common/src/utils/time";

export default {
  name: "ClientsStatisticsResume",

  data() {
    return {
      loading: true,
      registeredClients: 4,
      clientsWithServices: 3,
      clientsWithoutServices: 1,
      formattedDate: format(new Date(), "MMMM y")
    };
  },

  created() {
    this.getClientsStatistics();
  },

  methods: {
    getClientsStatistics() {
      this.loading = true;
      Reports.clientsStats()
        .then(res => {
          const {
            clientsWithoutServices,
            clientsWithService,
            registeredClients
          } = res;
          this.clientsWithoutServices = clientsWithoutServices;
          this.clientsWithService = clientsWithService;
          this.registeredClients = registeredClients;
        })
        .catch(() => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.stats-title {
  font-size: 1.2em;
}

.stats-number {
  font-size: 1.6em;
  font-weight: 600;
  color: $zubut-blue;
}
</style>
