<template>
  <div>
    <svg v-show="maxValue" class="radar-chart" :viewBox="viewBox">
      <g v-for="(tick, i) in ticks" :key="`cricle-${tick}`">
        <circle
          v-if="i !== ticks.length - 1"
          stroke="#CCCCCC"
          fill="none"
          :cx="width / 2"
          :cy="height / 2"
          :r="radialScale(tick)"
        ></circle>
        <text
          v-if="i !== 0"
          :x="width / 2"
          :y="height / 2 - radialScale(tick)"
          dx="10"
        >
          {{ tick }}
        </text>
      </g>

      <g v-for="(feature, i) in features" :key="`feature-${i}`">
        <line
          :x1="width / 2"
          :y1="height / 2"
          :x2="angleToCoordinate(getAngle(features.length, i), maxValue).x"
          :y2="angleToCoordinate(getAngle(features.length, i), maxValue).y"
          stroke="#969696"
        />
        <text
          :x="
            angleToCoordinate(
              getAngle(features.length, i),
              maxValue + maxValue * legendFactor
            ).x
          "
          :y="
            angleToCoordinate(
              getAngle(features.length, i),
              maxValue + maxValue * legendFactor
            ).y
          "
          class="feature font-weight-bold"
        >
          <slot
            name="feature"
            :feature="feature"
            :x="
              angleToCoordinate(
                getAngle(features.length, i),
                maxValue + maxValue * legendFactor
              ).x
            "
            :y="
              angleToCoordinate(
                getAngle(features.length, i),
                maxValue + maxValue * legendFactor
              ).y
            "
          >
            {{ feature }}
          </slot>
        </text>
      </g>

      <g v-for="(dataPoint, i) in data" :key="`data-point-${i}`">
        <path
          :d="line(getPathCoordinates(dataPoint))"
          stroke="rgba(0, 100, 255, 0.6)"
          stroke-width="3"
          fill="rgba(0, 123, 255, 0.5)"
        />
        <g
          v-for="(point, j) in getPathCoordinates(dataPoint)"
          v-show="dataPoint[j]"
          :key="`data-point-${j}`"
        >
          <circle
            r="5"
            fill="rgb(0, 100, 255)"
            :cx="point.x"
            :cy="point.y"
          ></circle>
          <circle
            :id="`data-point-${j}`"
            class="cursor-pointer"
            r="20"
            fill="rgba(0, 0, 0, 0)"
            :cx="point.x"
            :cy="point.y"
            @click="$emit('point-click', { value: dataPoint[j], feature: j })"
          ></circle>
          <b-popover
            :key="`popover-inner-${j}`"
            :target="`data-point-${j}`"
            triggers="hover"
            placement="top"
          >
            <slot name="tooltip" :value="dataPoint[j]" :feature="j"></slot>
          </b-popover>
        </g>
      </g>
    </svg>
    <div v-show="!maxValue" class="empty-container">
      <slot name="empty">
        No hay datos para mostrar
      </slot>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { computed, ref } from "@vue/composition-api";
import useChart from "@/composables/useChart";

export default {
  name: "RadarChart",

  props: {
    data: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 600
    },
    padding: {
      type: Number,
      default: 100
    },
    legendFactor: {
      type: Number,
      default: 0.2
    }
  },

  setup(props) {
    const translate = ref(`translate(${30},${-15})`);

    const getAngle = (n, i) => {
      return Math.PI / 2 + (2 * Math.PI * i) / n;
    };

    const viewBox = computed(() => {
      return `0 0 ${props.width} ${props.height}`;
    });

    const maxValue = computed(() => {
      let maxValue = 0;
      for (let dataPoint of props.data) {
        for (let key in dataPoint) {
          maxValue = dataPoint[key] > maxValue ? dataPoint[key] : maxValue;
        }
      }
      return maxValue;
    });

    const radialScale = computed(() =>
      d3
        .scaleLinear()
        .domain([0, maxValue.value])
        .range([0, props.width / 2 - props.padding])
    );
    const ticks = d3.ticks(0, maxValue.value, 5);

    const angleToCoordinate = (angle, value) => {
      const x = Math.cos(angle) * radialScale.value(value);
      const y = Math.sin(angle) * radialScale.value(value);
      return { x: 300 + x, y: 300 - y };
    };

    let line = d3
      .line()
      .x(d => d.x)
      .y(d => d.y);

    const features = computed(() => {
      const features = new Set();
      for (let dataPoint of props.data) {
        for (let key in dataPoint) {
          features.add(key);
        }
      }
      return [...features];
    });

    const getPathCoordinates = data_point => {
      let coordinates = [];
      for (let i = 0; i < features.value.length; i++) {
        let ft_name = features.value[i];
        let angle = getAngle(features.value.length, i);
        coordinates.push(angleToCoordinate(angle, data_point[ft_name] || 0));
      }
      return coordinates;
    };

    return {
      angleToCoordinate,
      features,
      getAngle,
      getPathCoordinates,
      line,
      maxValue,
      radialScale,
      ticks,
      viewBox
    };
  }
};
</script>

<style lang="scss" scoped>
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 383px;
  height: 100%;
}

.feature {
  text-anchor: middle;
}
</style>
