import * as d3 from "d3";
import { computed } from "@vue/composition-api";

export default function useChart({ data, height, width }) {
  const xAxisTranslate = computed(() => {
    return `translate(${0},${height})`;
  });

  const rangeX = computed(() => {
    return [0, width - 55];
  });

  const xScale = computed(() => {
    const xScale = d3
      .scaleBand()
      .domain(data.map(el => el.x))
      .rangeRound(rangeX.value)
      .padding(0.2);
    d3.axisBottom().scale(xScale);
    return xScale;
  });

  const xAxisScales = computed(() => {
    return d3.axisBottom().scale(xScale.value);
  });

  const rangeY = computed(() => {
    return [height, 30];
  });
  const yAxisTranslate = computed(() => {
    return "translate(0,0)";
  });

  const yGridTranslate = computed(() => {
    return "translate(0,0)";
  });

  const yMaxValue = computed(() => {
    return d3.max(data.map(d => d.y));
  });

  const yIncrement = computed(() => {
    const decimalPlaces = yMaxValue.value.toString().length;
    return Math.pow(10, decimalPlaces - 1);
  });

  const yTopLimit = computed(() => {
    const firstNum = parseInt(yMaxValue.value.toString().charAt(0)) + 1;
    return firstNum * yIncrement.value;
  });

  const yScale = computed(() => {
    const yScale = d3
      .scaleLinear()
      .domain([0, yTopLimit.value])
      .range(rangeY.value);
    d3.axisLeft().scale(yScale);
    return yScale;
  });

  const yAxis = computed(() => {
    const yAxis = d3.axisLeft().scale(yScale.value);
    return yAxis;
  });

  const yGrid = computed(() => {
    return d3
      .axisLeft()
      .scale(yScale.value)
      .tickSize(-(width - 55), 0, 0)
      .tickFormat("");
  });

  const path = computed(() => {
    return d3
      .line()
      .x(d => xScale.value(d.x))
      .y(d => yScale.value(d.y));
  });

  const line = computed(() => {
    return path.value(data);
  });

  const viewBox = computed(() => {
    return `0 0 ${width} ${height}`;
  });

  return {
    line,
    xScale,
    xAxisScales,
    xAxisTranslate,
    yAxis,
    yAxisTranslate,
    yGrid,
    yGridTranslate,
    yScale,
    viewBox
  };
}
