<template>
  <g class="chart-axis" :transform="translate">
    <g ref="axis"> </g>
  </g>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "ChartAxis",

  props: {
    scales: {
      type: Function,
      required: true
    },
    translate: {
      type: String,
      default: "translate(0,0)"
    }
  },

  watch: {
    scales() {
      this.setScales();
    }
  },

  mounted() {
    this.setScales();
  },

  methods: {
    setScales() {
      d3.select(this.$refs.axis).call(this.scales);
    }
  }
};
</script>

<style lang="scss">
.chart-axis {
  .domain {
    stroke: $very-light-grey;
  }
  .tick {
    line {
      stroke: $very-light-grey;
    }

    text {
      fill: $cinder;
      font-size: 0.7em;
    }
  }
}
</style>
