<template>
  <div>
    <b-row>
      <b-col lg="4">
        <div class="rounded border p-4 h-100">
          <active-services-per-city />
        </div>
      </b-col>
      <b-col class="mt-4 mt-lg-0" lg="8">
        <div class="rounded border p-4">
          <deliveries-chart />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col lg="6">
        <div class="rounded border p-4 h-100">
          <services-status-chart />
        </div>
      </b-col>
      <b-col class="mt-4 mt-lg-0" lg="6">
        <div class="rounded border p-4 h-100">
          <heatmap-chart />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DeliveriesChart from "./DeliveriesChart";
import ActiveServicesPerCity from "./ActiveServicesPerCity";
import ServicesStatusChart from "./ServicesStatusChart";
import HeatmapChart from "./HeatmapChart";

export default {
  name: "OperationsStatistics",

  components: {
    ActiveServicesPerCity,
    DeliveriesChart,
    ServicesStatusChart,
    HeatmapChart
  },

  data() {
    return {};
  }
};
</script>
