<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <div class="h5 font-weight-bold mb-3">
        Mapa de Calor
      </div>
    </div>
    <div class="d-flex mt-3 mt-md-0 mb-4">
      <z-dropdown
        id="filter-date"
        v-model="selectedDate"
        class="mr-3"
        toggle-class="h-100"
        name="Fecha"
        :options="dateOptions"
        :disabled="loading"
      />
      <z-date-picker
        v-show="showCustomDateInputs"
        v-model="customDate.from"
        placeholder="Desde"
        class="mr-3"
        @changed="getHeatmapPoints"
      />
      <z-date-picker
        v-show="showCustomDateInputs"
        v-model="customDate.to"
        placeholder="Hasta"
        class="mr-3"
        @changed="getHeatmapPoints"
      />
      <z-dropdown
        id="filter-city"
        v-model="selectedCity"
        class="mr-3"
        toggle-class="h-100"
        name="Ciudad"
        :options="cityOptions"
        :disabled="loading"
      />
    </div>
    <div id="map">
      <heatmap
        :points="points"
        :loading="loading"
        :center="center"
        :center-lat="center.lat"
        :center-lng="center.lng"
      />
    </div>
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDatePicker from "@zubut/common/src/components/ZDatePicker";
import { dateRange, formatToISO } from "@zubut/common/src/utils/time";
import Heatmap from "@/app/components/Heatmap.vue";
import DateFilters from "@/constants/filters/date";
import Reports from "@/services/reports";

export default {
  name: "HeatmapChart",

  components: {
    Heatmap,
    ZDropdown,
    ZDatePicker
  },

  data() {
    return {
      dateOptions: [
        { value: DateFilters.NUM_THIS_MONTH, text: DateFilters.THIS_MONTH },
        { value: DateFilters.NUM_LAST_MONTH, text: DateFilters.LAST_MONTH },
        { value: DateFilters.NUM_THIS_YEAR, text: DateFilters.THIS_YEAR },
        { value: DateFilters.NUM_CUSTOM, text: DateFilters.CUSTOM }
      ],
      selectedDate: DateFilters.NUM_THIS_MONTH,
      selectedCity: null,
      points: [],
      loading: true,
      customDate: {
        from: null,
        to: null
      },
      center: {
        lat: 20.6737777,
        lng: -103.4054536
      }
    };
  },

  computed: {
    cityOptions() {
      return this.$store.getters["cities/abbrMapCityOptions"];
    },
    showCustomDateInputs() {
      return this.selectedDate === DateFilters.NUM_CUSTOM;
    }
  },

  watch: {
    selectedCity(city) {
      const c = this.cityOptions.find(el => el.value === city);
      this.center = {
        lat: c?.position?.latitude ?? this.center.lat,
        lng: c?.position?.longitude ?? this.center.lng
      };
      this.getHeatmapPoints();
    },
    selectedDate() {
      if (this.selectedDate !== DateFilters.NUM_CUSTOM) {
        this.getHeatmapPoints();
      }
    }
  },

  created() {
    this.getHeatmapPoints();
  },

  methods: {
    getHeatmapPoints() {
      this.loading = true;
      let from = null;
      let to = null;

      if (this.selectedDate !== DateFilters.NUM_CUSTOM) {
        const date = dateRange(this.selectedDate);
        this.customDate.from = date.from;
        this.customDate.to = date.until;
        from = formatToISO(date.from);
        to = formatToISO(date.until);
      } else {
        from = this.customDate.from;
        to = this.customDate.to;
      }

      this.points = [];
      Reports.weightedDestinations({
        from,
        until: to,
        cityId: this.selectedCity
      })
        .then(res => {
          res.data.map(destination => {
            this.points.push({
              lat: destination.position.lat,
              lng: destination.position.lng
            });
          });
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 400px;
  margin: 0 auto;
}
</style>
