<template>
  <div class="h-100">
    <div class="d-md-flex justify-content-between">
      <div class="h5 font-weight-bold mb-0">
        Estado de servicios del día
      </div>
      <div class="d-flex mt-3 mt-sm-0">
        <z-dropdown
          id="filter-clients"
          v-model="selectedClient"
          class="mr-3"
          toggle-class="h-100"
          name="Cliente"
          :options="clientsOptions"
          search
        />
      </div>
    </div>
    <div>
      <div v-if="loading" class="loading-container rounded">
        <loading-spinner />
      </div>
      <radar-chart
        v-else
        :data="data"
        @point-click="filterServicesLising($event.feature)"
      >
        <template #feature="{ feature, x, y }">
          <tspan
            v-for="(word, i) in serviceStatus.get[feature].split(' ')"
            :key="`feature-${i}`"
            :x="x"
            :y="y"
            :dy="1.2 * i + 'em'"
          >
            {{ word }}
          </tspan>
        </template>
        <template #tooltip="item">
          <div class="text-center" @click="filterServicesLising(item.feature)">
            <div><b>Total:</b> {{ item.value }}</div>
            <div>Haz clic para ver servicios</div>
          </div>
        </template>
      </radar-chart>
    </div>
  </div>
</template>

<script>
import RadarChart from "@/app/components/Charts/RadarChart.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import Reports from "@/services/reports";
import DateFilters from "@/constants/filters/date";
import ServiceStatus from "@/constants/services/status";
import * as servicesMutations from "@/store/modules/services/mutations-types";

export default {
  components: {
    ZDropdown,
    RadarChart
  },

  data() {
    return {
      loading: true,
      data: [],
      selectedClient: null,
      serviceStatus: ServiceStatus
    };
  },

  computed: {
    clientsOptions() {
      return this.$store.getters["clients/clientsOptions"];
    }
  },

  watch: {
    selectedClient() {
      this.getDailyServices();
    }
  },

  created() {
    this.getDailyServices();
  },

  methods: {
    getDailyServices() {
      this.loading = true;
      Reports.dailyServiceStatus({
        clientId: this.selectedClient
      })
        .then(res => {
          this.data = [res];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterServicesLising(status) {
      console.log("filterServicesLising", status);
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_STATUS}`,
        status
      );
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_DATE}`,
        DateFilters.NUM_TODAY
      );

      if (this.selectedClient) {
        this.$store.commit(
          `services/${servicesMutations.UPDATE_SEARCH_TEXT}`,
          this.selectedClient
        );
      }
      this.$router.push({ name: "services" });
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  min-height: 383px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: $white;
}
</style>
