<template>
  <div>
    <svg class="line-chart" :viewBox="viewBox">
      <g :transform="translate">
        <chart-axis
          class="x-axis"
          :scales="xAxisScales"
          :translate="xAxisTranslate"
        />
        <chart-axis
          class="y-axis"
          :scales="yAxis"
          :translate="yAxisTranslate"
        />
        <chart-axis
          class="y-grid"
          :scales="yGrid"
          :translate="yGridTranslate"
        />
        <g class="line">
          <path stroke="#007bff" :d="line" stroke-width="1" fill="none" />
          <g class="line-dots">
            <g
              v-for="(item, i) in data"
              :id="`line-dot-${i}`"
              :key="`line-dot-${i}`"
              class="line-dot cursor-pointer"
            >
              <text
                class="line-dot-text"
                :dx="xScale(item.x)"
                :dy="yScale(item.y)"
                x="-6"
                y="-6"
              >
                {{ item.y }}
              </text>
              <circle
                r="4"
                stroke="#007bff"
                stroke-width="1"
                fill="#fff"
                :cx="xScale(item.x)"
                :cy="yScale(item.y)"
              ></circle>
              <circle
                r="2"
                stroke="#007bff"
                fill="#9FCEFF"
                :cx="xScale(item.x)"
                :cy="yScale(item.y)"
              ></circle>
              <b-popover
                :key="`line-chart-popover-inner-${i}`"
                :target="`line-dot-${i}`"
                triggers="hover"
                placement="top"
              >
                <slot name="tooltip" :data="item"></slot>
              </b-popover>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import ChartAxis from "./ChartAxis";
import { format } from "@zubut/common/src/utils/time";

export default {
  name: "LineChart",

  components: {
    ChartAxis
  },

  props: {
    data: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 460
    },
    height: {
      type: Number,
      default: 210
    },
    xAxis: {
      type: Object,
      default: () => ({
        format: val => format(val, "MMM")
      })
    }
  },

  data() {
    return {
      padding: 0,
      translate: "translate(" + 30 + "," + -15 + ")",
      axisKey: 0
    };
  },

  computed: {
    xAxisTranslate() {
      return "translate(0," + this.height + ")";
    },
    xAxisScales() {
      const xAxis = d3
        .axisBottom()
        .scale(this.xScale)
        .ticks(this.data.length - 1)
        .tickFormat(d => this.xAxis.format(d));
      return xAxis;
    },
    xScale() {
      const xScale = d3
        .scaleTime()
        .domain(d3.extent(this.data, d => d.x))
        .rangeRound(this.rangeX);
      return xScale;
    },
    yAxisTranslate() {
      return "translate(0,0)";
    },
    yGridTranslate() {
      return "translate(0,0)";
    },
    yAxis() {
      const yAxis = d3
        .axisLeft()
        .scale(this.yScale)
        .ticks(this.yTopLimit / this.yIncrement);
      return yAxis;
    },
    yScale() {
      const yScale = d3
        .scaleLinear()
        .domain([0, this.yTopLimit])
        .range(this.rangeY);
      d3.axisLeft().scale(yScale);
      return yScale;
    },
    yMaxValue() {
      return d3.max(this.data.map(d => d.y));
    },
    yIncrement() {
      const decimalPlaces = this.yMaxValue.toString().length;
      return Math.pow(10, decimalPlaces - 1);
    },
    yTopLimit() {
      const firstNum = parseInt(this.yMaxValue.toString().charAt(0)) + 1;
      return firstNum * this.yIncrement;
    },
    yGrid() {
      return d3
        .axisLeft()
        .scale(this.yScale)
        .tickSize(-(this.width - 55), 0, 0)
        .tickFormat("");
    },
    rangeX() {
      const width = this.width - 20;
      return [0, width - 35];
    },
    rangeY() {
      const height = this.height - 0;
      return [height, 30];
    },
    path() {
      return d3
        .line()
        .x(d => this.xScale(d.x))
        .y(d => this.yScale(d.y));
    },
    line() {
      return this.path(this.data);
    },
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.line-dot-text {
  font-size: 0.5em;
}

path,
circle,
line {
  transition: all 500ms ease;
}
</style>
