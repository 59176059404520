<template>
  <b-card>
    <div class="p-3">
      <div class="d-flex justify-content-between">
        <div>
          <span class="card-title">Estadísticas</span>
        </div>
        <div>
          <z-button
            v-tooltip.bottom="'Exportar'"
            variant="secondary"
            fit="auto"
            class="text-dark mr-3 mb-0 h6"
          >
            <font-awesome-icon icon="download" />
          </z-button>
          <z-button
            v-tooltip.bottom="'Presentar'"
            variant="secondary"
            fit="auto"
            class="text-dark mb-0 h6"
          >
            <font-awesome-icon icon="full-screen" />
          </z-button>
        </div>
      </div>
      <div class="mt-5">
        <b-tabs
          v-model="tabIndex"
          class="h-100"
          content-class="pt-4 h-100 pb-5"
        >
          <b-tab
            title="Operaciones"
            :active="!$route.query.tab || $route.query.tab === 'operaciones'"
            lazy
            @click="changeTab('operaciones')"
          >
            <operations-statistics />
          </b-tab>
          <b-tab
            title="Clientes"
            :active="$route.query.tab === 'clientes'"
            lazy
            @click="changeTab('clientes')"
          >
            <clients-statistics />
          </b-tab>
          <b-tab
            title="Finanzas"
            :active="$route.query.tab === 'finanzas'"
            lazy
            @click="changeTab('finanzas')"
          >
            <finances-statistics />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </b-card>
</template>

<script>
import ClientsStatistics from "./ClientsStatistics/ClientsStatistics.vue";
import FinancesStatistics from "./FinancesStatistics/FinancesStatistics.vue";
import OperationsStatistics from "./OperationsStatistics/OperationsStatistics.vue";

export default {
  name: "Statistics",

  components: {
    ClientsStatistics,
    FinancesStatistics,
    OperationsStatistics
  },

  data() {
    return {
      tabIndex: 0
    };
  },

  created() {
    this.getCities();
    this.getClients();
  },

  methods: {
    getCities() {
      this.$store.dispatch("cities/getCities");
    },
    getClients() {
      this.$store.dispatch("clients/getClients");
    },
    changeTab(tab) {
      this.$router
        .replace({ name: "statistics", query: { tab } })
        .catch(error => {
          this.$captureError(error);
        });
    }
  }
};
</script>
