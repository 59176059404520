<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <div class="h5 font-weight-bold mb-3">
        No. de Entregas / Servicios
      </div>
    </div>

    <div class="d-md-flex mt-3 mt-md-0">
      <z-dropdown
        id="filter-type"
        v-model="selectedDisplayType"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Tipo"
        :options="displayTypeOptions"
        :disabled="loading"
      />
      <z-dropdown
        id="filter-peridiocity"
        v-model="selectedPeridiocity"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Periodicidad"
        :options="peridiocityOptions"
        :disabled="loading"
      />
      <z-dropdown
        id="filter-1"
        v-model="selectedServiceType"
        class="mr-3 mt-3 mt-md-0"
        toggle-class="h-100"
        name="Servicio"
        :disabled="loading"
        :options="serviceTypeOptions"
      />
      <z-dropdown
        id="filter-clients"
        v-model="selectedClient"
        class="mr-3"
        toggle-class="h-100"
        name="Cliente"
        :options="clientsOptions"
        search
      />
    </div>
    <div class="chart-container border rounded mt-4 bg-light position-relative">
      <div
        v-show="loading"
        class="loading-container rounded"
        :class="{ 'position-absolute fixed-top': selectedData.length }"
      >
        <loading-spinner />
      </div>
      <line-chart
        v-if="selectedData.length"
        class="p-3"
        :data="parsedData"
        :x-axis="xAxisConfig"
      >
        <template #tooltip="{ data }">
          <div>
            Fecha:
            <span class="font-weight-bold">{{ formatDate(data.x) }}</span>
          </div>
          <div>
            Entregas: <span class="font-weight-bold">{{ data.y }}</span>
          </div>
        </template>
      </line-chart>
    </div>
  </div>
</template>

<script>
import { parseISO } from "date-fns";
import { format } from "@zubut/common/src/utils/time";
import LineChart from "@/app/components/Charts/LineChart.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ServiceType from "@zubut/common/src/constants/services/type";
import Services from "@/services/reports";
import _debounce from "lodash/debounce";
import _random from "lodash/random";

export default {
  name: "DeliveriesChart",

  components: {
    LineChart,
    ZDropdown
  },

  data() {
    return {
      chartKey: 0,
      selectedData: [],
      selectedDisplayType: 1,
      selectedClient: null,
      displayTypeOptions: [
        { value: 1, text: "Servicios" },
        { value: 2, text: "Entregas" }
      ],
      selectedPeridiocity: "d",
      peridiocityOptions: [
        { value: "d", text: "Día" },
        { value: "w", text: "Semana" },
        { value: "m", text: "Mes" },
        { value: "q", text: "Trimestre" },
        { value: "y", text: "Año" }
      ],
      selectedServiceType: null,
      serviceTypeOptions: [{ text: "Todos", value: null }].concat(
        ServiceType.options
      ),
      loading: true,
      xAxisConfig: {
        format: val => format(val, "MMM")
      },
      tooltipFormat: "MMM yyyy"
    };
  },

  computed: {
    clientsOptions() {
      return this.$store.getters["clients/clientsOptions"];
    },
    parsedData() {
      return this.selectedData.map(d => {
        return {
          y: d.count,
          x: parseISO(d.date)
        };
      });
    }
  },

  watch: {
    selectedClient() {
      this.getDeliveries();
    },
    selectedDisplayType() {
      this.getDeliveries();
    },
    selectedPeridiocity() {
      this.getDeliveries();
    },
    selectedServiceType() {
      this.getDeliveries();
    }
  },

  mounted() {
    this.getDeliveries();
  },

  methods: {
    getDeliveries() {
      this.loading = true;
      const filter = {
        displayType: this.selectedDisplayType,
        period: this.selectedPeridiocity
      };

      if (this.selectedServiceType) {
        filter.serviceType = this.selectedServiceType;
      }

      if (this.selectedClient) {
        filter.clientId = this.selectedClient;
      }

      Services.deliveriesByPeriod(filter)
        .then(res => {
          let rangeDate = "from";

          if (this.selectedPeridiocity === "w") {
            rangeDate = "until";
          }

          this.selectedData = res.map(el => {
            return {
              date: el.range[rangeDate],
              count: el.count
            };
          });

          this.formatXAxis();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(date) {
      return format(date, this.tooltipFormat);
    },
    formatXAxis() {
      if (this.selectedPeridiocity === "d") {
        this.tooltipFormat = "EEEE d";
        this.xAxisConfig.format = val => format(val, "E d");
      } else if (this.selectedPeridiocity === "w") {
        this.tooltipFormat = "'Semana' w";
        this.xAxisConfig.format = val => format(val, "'Sem.' w");
      } else if (this.selectedPeridiocity === "m") {
        this.tooltipFormat = "MMMM";
        this.xAxisConfig.format = val => format(val, "MMM");
      } else if (this.selectedPeridiocity === "q") {
        this.tooltipFormat = "'Trimestre' Q";
        this.xAxisConfig.format = val => format(val, "'Q' Q");
      } else {
        this.tooltipFormat = "yyyy";
        this.xAxisConfig.format = val => format(val, "yyyy");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  min-height: 150px;
}

.loading-container {
  display: flex;
  min-height: 383px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: $white;
  opacity: 0.4;
}

@include media-breakpoint-up(md) {
  .chart-container {
    min-height: 383px;
  }
}
</style>
