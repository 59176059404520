import loopback from "@/services/loopback";
import modelObject from "@/services/lb.services";

modelObject.setModel("Reports");

const Reports = Object.assign(
  {},
  {
    relation: "",

    relation: "",
    activeServicesPerCity(params) {
      return loopback.get("/Reports/activeServicesPerCity", { params });
    },

    clientsStats() {
      return loopback.get("/Reports/clientsStats");
    },

    dailyServiceStatus(params) {
      return loopback.get("/Reports/dailyServiceStatus", { params });
    },

    deliveriesByPeriod(data) {
      return loopback.post("/Reports/deliveriesByPeriod", data);
    },

    mostRequestedServices(params) {
      return loopback.get("/Reports/mostRequestedServices", { params });
    },

    weightedDestinations(params) {
      return loopback.get("/Reports/weightedDestinations", { params });
    }
  },
  modelObject
);

export default Reports;
