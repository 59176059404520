<template>
  <div>
    <svg class="line-chart" :viewBox="viewBox">
      <g :transform="translate">
        <chart-axis
          class="x-axis"
          :scales="xAxisScales"
          :translate="xAxisTranslate"
        />
        <chart-axis
          class="y-axis"
          :scales="yAxis"
          :translate="yAxisTranslate"
        />
        <chart-axis
          class="y-grid"
          :scales="yGrid"
          :translate="yGridTranslate"
        />
        <g class="bars">
          <g class="bar-items">
            <g
              v-for="(item, i) in data"
              :id="`bar-item-${i}`"
              :key="`bar-item-${i}`"
              class="line-dot"
            >
              <rect
                :x="xScale(item.x)"
                :y="yScale(item.y)"
                :width="xScale.bandwidth()"
                :height="height - yScale(item.y)"
                fill="#007bff"
              />
              <text
                class="bar-item-text"
                :dx="xScale(item.x) + xScale.bandwidth() / 2"
                :dy="yScale(item.y)"
                x="-6"
                y="-6"
              >
                {{ item.y }}
              </text>
              <b-popover
                :key="`bar-chart-popover-inner-${i}`"
                :target="`bar-item-${i}`"
                triggers="hover"
                placement="top"
              >
                <slot name="tooltip" :data="item"></slot>
              </b-popover>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import ChartAxis from "./ChartAxis";
import { ref } from "@vue/composition-api";
import useChart from "@/composables/useChart";

export default {
  name: "BarChart",

  components: {
    ChartAxis
  },

  props: {
    data: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 460
    },
    height: {
      type: Number,
      default: 210
    }
  },

  setup(props) {
    const translate = ref(`translate(${30},${-15})`);

    const {
      xScale,
      xAxisScales,
      xAxisTranslate,
      yAxis,
      yAxisTranslate,
      yGrid,
      yGridTranslate,
      yScale,
      viewBox
    } = useChart({ ...props });

    return {
      translate,
      xScale,
      xAxisScales,
      xAxisTranslate,
      yAxis,
      yAxisTranslate,
      yGrid,
      yGridTranslate,
      yScale,
      viewBox
    };
  }
};
</script>

<style lang="scss" scoped>
.bar-item-text {
  font-size: 1em;
}

rect,
text {
  transition: all 500ms ease;
}
</style>
