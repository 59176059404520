<template>
  <div class="pie-chart">
    <svg v-show="data.length" class="pie-chart-svg" :viewBox="viewBox">
      <g :transform="translate">
        <g class="slices">
          <g v-for="(item, index) in pieData" :key="`path-${index}`">
            <path
              :id="`feature-${index}`"
              :d="arc(index)"
              :fill="activeIndex === index ? activeColor : colors(item.feature)"
              class="slice cursor-pointer"
              @mouseover="setActiveFeature(index)"
              @mouseout="setActiveFeature(null)"
              @click="$emit('feature-selected', data[index])"
            ></path>
          </g>
        </g>
        <g text-anchor="middle">
          <text>
            <slot
              name="center"
              :data="data[activeIndex]"
              :index="activeIndex"
              :total="total"
            >
              <tspan>
                {{
                  activeIndex === null ? "Total" : data[activeIndex][feature]
                }}
              </tspan>
              <tspan y="1.4em" x="0">
                {{ total }}
              </tspan>
            </slot>
          </text>
        </g>
      </g>
    </svg>
    <svg
      v-show="data.length"
      class="pie-chart-legend"
      :viewBox="`0 0 ${width} ${data.length * 50}`"
    >
      <g class="legend">
        <g
          v-for="(item, index) in pieData"
          :key="`legend-${index}`"
          class="legend-item"
          :transform="`translate(0, ${20 + index * 30})`"
        >
          <rect width="15" height="15" :fill="colors(item.feature)"></rect>
          <text dx="25" dy="15">{{ data[index][legendKey || feature] }}</text>
        </g>
      </g>
    </svg>
    <div v-show="!data.length" class="empty-container">
      <slot name="empty">
        No hay datos para mostrar
      </slot>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { computed, ref, toRefs } from "@vue/composition-api";
import useChart from "@/composables/useChart";

export default {
  name: "RadarChart",

  props: {
    activeColor: {
      type: String,
      default: "#10466d"
    },
    data: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 350
    },
    height: {
      type: Number,
      default: 600
    },
    padding: {
      type: Number,
      default: 40
    },
    legendKey: {
      type: String,
      default: ""
    },
    feature: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const { data, feature, value, width, height, padding } = toRefs(props);
    const viewBox = computed(() => {
      return `0 0 ${width.value} ${width.value}`;
    });
    const translate = computed(() => {
      return `translate(${width.value / 2},${width.value / 2})`;
    });

    const features = computed(() => {
      return data.value.map(d => d[feature.value]);
    });
    const pieData = computed(() => {
      return data.value.map(d => ({
        feature: d[feature.value],
        value: d[value.value]
      }));
    });
    const radius = Math.min(width.value, width.value) / 2 - padding.value;

    // Compute the position of each group on the pie:
    const pie = d3.pie().value(d => d.value);

    const groups = computed(() => {
      return pie(pieData.value);
    });

    // set the color scale
    const colors = d3
      .scaleOrdinal()
      .domain(features.value)
      .range(d3.schemePaired);

    const arc = index => {
      return d3
        .arc()
        .innerRadius(radius * 0.5)
        .outerRadius(radius)(groups.value[index]);
    };

    const activeIndex = ref(null);

    const setActiveFeature = index => {
      activeIndex.value = index;
    };

    const total = computed(() => {
      let total = 0;
      if (activeIndex.value === null) {
        data.value.forEach(item => {
          total += item[value.value];
        });
      } else {
        total = data.value[activeIndex.value][value.value];
      }

      return total;
    });

    return {
      arc,
      activeIndex,
      pieData,
      colors,
      setActiveFeature,
      total,
      translate,
      viewBox
    };
  }
};
</script>

<style lang="scss" scoped>
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 383px;
  height: 100%;
}

.slice {
  transition: fill 300ms ease;
}
</style>
