<template>
  <div>
    <div class="d-md-flex justify-content-between">
      <div class="h5 font-weight-bold mb-0">
        Servicios activos por ciudad
      </div>
    </div>
    <div>
      <pie-chart
        :data="data"
        feature="abbreviation"
        value="count"
        legend-key="cityName"
        @feature-selected="filterServicesLising"
      >
        <template #center="{ data: city, index, total }">
          <tspan class="font-weight-bold">
            {{ index === null ? "Total" : city.abbreviation }}
          </tspan>
          <tspan x="0" y="1.4em">
            {{ total }}
          </tspan>
        </template>
      </pie-chart>
    </div>
  </div>
</template>

<script>
import PieChart from "@/app/components/Charts/PieChart";
import Reports from "@/services/reports";
import DateFilters from "@/constants/filters/date";
import ServiceStatus from "@/constants/services/status";
import * as servicesMutations from "@/store/modules/services/mutations-types";

export default {
  name: "ActiveServicesPerCity",

  components: {
    PieChart
  },

  data() {
    return {
      data: []
    };
  },

  mounted() {
    this.getActiveServicesPerCity();
  },

  methods: {
    getActiveServicesPerCity() {
      Reports.activeServicesPerCity().then(res => {
        this.data = res;
      });
    },
    filterServicesLising(data) {
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_STATUS}`,
        ServiceStatus.NUM_ACTIVE
      );
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_DATE}`,
        DateFilters.NUM_TODAY
      );
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_CITY}`,
        data.cityId
      );
      this.$store.commit(
        `services/${servicesMutations.UPDATE_FILTER_HAS_STATISTICS_FILTERS}`,
        true
      );
      this.$router.push({ name: "services" });
    }
  }
};
</script>
